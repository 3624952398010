import TitleCommon from "../../../components/Common/Title";
import {
  Des,
  DesGroup,
  NomicsContainer,
  NomicsHint,
  NomicsInner,
  NomicsList,
  NomicsWrapper,
} from "./styled";
import nomics_img from "../../../assets/Home/Nomics/nomics_img.png";

const Nomics = () => {
  return (
    <NomicsContainer>
      <NomicsWrapper>
        <figure>
          <img src={nomics_img} alt="img" />
        </figure>
        <NomicsInner>
          <TitleCommon
            title="POONG-NOMICS"
            subTitle="Grow with Us"
            titleColor="#FF7A00"
            subTitleColor="#fff"
          />
          <NomicsList>
            {nomicsData.map((item, index) => {
              return (
                <li key={index}>
                  <p>{item.title}</p>
                  <span>{item.value}</span>
                </li>
              );
            })}
          </NomicsList>
          <DesGroup>
            {tokentData.map((item, index) => (
              <Des key={item.title}>
                <h3>{item.title} </h3>
                <p>
                  {item.value} {item.isCopy && <button></button>}
                </p>
              </Des>
            ))}
          </DesGroup>
          <NomicsHint>
            <p>*</p>
            <p>
              No Taxes, No Bullshit.It’s that simple.
              <br className="sm" /> LP tokens are burnt, and contract ownership
              is
              <br className="sm" /> renounced.
            </p>
          </NomicsHint>
        </NomicsInner>
      </NomicsWrapper>
    </NomicsContainer>
  );
};

const nomicsData = [
  {
    title: "Liquidity",
    value: "95%",
  },
  {
    title: "Airdrop",
    value: "5%",
  },
  // {
  //   title: "Public",
  //   value: "30%",
  // },
  // {
  //   title: "marketing",
  //   value: "10%",
  // },
  // {
  //   title: "Staking",
  //   value: "15%",
  // },
  // {
  //   title: "Listing",
  //   value: "10%",
  // },
];
const tokentData = [
  {
    title: "TOken name",
    value: "poong",
  },
  {
    title: "symbol",
    value: "poong",
  },
  {
    title: "total supply",
    value: "69.420.000.000",
  },
  {
    title: "decimal",
    value: "18",
  },
  {
    title: "blockchain",
    value: "avalanche c-chain (43114)",
  },
  {
    title: "address",
    value: "tba",
    isCopy: true,
  },
];
export default Nomics;
