import Footer from "./components/Footer";
import Header from "./components/Header";
import Home from "./page/Home";
import { AppContainer } from "./styled";
import "./App.css";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { ContextProvider } from "./components/Context";
import { Toaster } from "react-hot-toast";
import { createWeb3Modal } from "@web3modal/wagmi/react";
import { WagmiProvider } from "wagmi";
import { avalanche } from "viem/chains";
import { useEffect } from "react";
import { ReactLenis } from "@studio-freight/react-lenis";
import Airdrop from "./page/Airdrop";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { defaultWagmiConfig } from "@web3modal/wagmi";

const App = () => {
  const metadata = {
    name: "Poong",
    description: "Poong",
    url: "https://poong.vip/",
    icons: [""],
  };
  const projectId = "17c32b7d53c43402b9413971337ef0b2";
  const chains = [avalanche] as const;
  const config = defaultWagmiConfig({
    chains,
    projectId,
    metadata,
  });
  createWeb3Modal({
    wagmiConfig: config,
    projectId,
  });
  const { pathname } = useLocation();
  const queryClient = new QueryClient();

  useEffect(() => {
    if (pathname) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <ContextProvider>
          <Toaster position="top-right" reverseOrder={false} />
          <ReactLenis root>
            <AppContainer>
              <Header />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/airdrop" element={<Airdrop />} />
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
              <Footer />
            </AppContainer>
          </ReactLenis>
        </ContextProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
};

export default App;
