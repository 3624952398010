import React, { useEffect, useState } from "react";
import {
  AirdropBlockAddress,
  AirdropBlockAmount,
  AirdropBlockHash,
  AirdropBlockRight,
  AirdropBlockRightFooter,
  AirdropBlockRightListButtons,
  AirdropBlockRightPage,
  AirdropBlockRightTable,
  AirdropBlockTime,
  AirdropContainer,
  AirdropDecor,
  AirdropEar,
  AirdropLeft,
  AirdropLeftBlockBannerClaim,
  AirdropLeftBlockBannerImage,
  AirdropLeftBlockBannerInner,
  AirdropLeftBlockClaim,
  AirdropLeftBlockClaimTitle,
  AirdropLeftBlockClaimTotal,
  AirdropLeftBlockProgress,
  AirdropLeftBlockProgressDetail,
  AirdropLeftBlockProgressDetailCompleted,
  AirdropLeftBlockTitle,
  AirdropLeftButtonsDecord,
  AirdropLeftConnectWallet,
  AirdropLeftTitle,
  AirdropRight,
  AirdropWrapper,
  AirdropWrapperInner,
} from "./styled";
import { Table, TableProps } from "antd";
import GrowWave from "../Home/Grow/Wave";
import ModalClaim from "./ModalClaim";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useAccount, useSignMessage } from "wagmi";
import Web3 from "web3";
import { useSwitchChain } from "wagmi";
import abiTokenDefault from "../../contract/ABI/ABI_NFT.json";

interface DataType {
  key: string;
  address: string;
  time: string;
  hash: string;
  amount: string;
}

const columns: TableProps<DataType>["columns"] = [
  {
    title: "ADDRESS",
    dataIndex: "address",
    key: "address",
    render: (text) => (
      <AirdropBlockAddress>
        <span>{text}</span>
        <img src="/assets/images/Airdrop/icn_copy.png" alt="" />
      </AirdropBlockAddress>
    ),
  },
  {
    title: "TIME",
    dataIndex: "time",
    key: "time",
    render: (text) => <AirdropBlockTime>{text}</AirdropBlockTime>,
  },
  {
    title: "HASH",
    dataIndex: "hash",
    key: "hash",
    render: (text) => <AirdropBlockHash>{text}</AirdropBlockHash>,
  },
  {
    title: "AMOUNT",
    key: "amount",
    dataIndex: "amount",
    render: (text) => <AirdropBlockAmount>{text}</AirdropBlockAmount>,
  },
];

const data: DataType[] = [
  {
    key: "1",
    address: "0xd3...12fa",
    time: "02:02:00 12/06/2024",
    hash: "09dx...12a9",
    amount: "193,002",
  },
  {
    key: "1",
    address: "0xd3...12fa",
    time: "02:02:00 12/06/2024",
    hash: "09dx...12a9",
    amount: "193,002",
  },
];
declare const window: Window & typeof globalThis & { ethereum: any };

const Airdrop = () => {
  const web3 = new Web3(window.ethereum);
  const { open } = useWeb3Modal();
  const { address, chainId } = useAccount();
  const [sign,setSign] = useState('')
  console.log('sign',sign)
  const [completed, setComplered] = useState(60);
  const [modalOpen, setModalOpen] = useState(true);
  const { switchChain } = useSwitchChain();
  const dataSign:any = useSignMessage()

  useEffect(()=>{
    setSign(dataSign?.data)
  },[dataSign])

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  // Claim Token
  const handleClaimToken = async () => {
    dataSign.signMessage({message: 'SignMessage'})
    // call contract
    const contractTokenFrom: any = new web3.eth.Contract(
      abiTokenDefault as any,
      "0xC3b5C89008A4F8D37B769dc67272485D6086b80C"
    );
    if (address && chainId !== 43114) {
      await switchChain({
        chainId: 43114,
      });
    }
    try {
      contractTokenFrom.method
        .claim(
          "1",
        "1",
        "1",
        )
        .then((res: any) => {
          if (res.status) {
            console.log("success");
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <AirdropContainer>
      <AirdropWrapper>
        <AirdropWrapperInner>
          <AirdropEar></AirdropEar>
          <AirdropDecor>
            <img src="/assets/images/Airdrop/img_decor.png" alt="" />
          </AirdropDecor>
          <AirdropLeft>
            <AirdropLeftBlockTitle>
              <AirdropLeftButtonsDecord>
                <li></li>
                <li></li>
                <li></li>
              </AirdropLeftButtonsDecord>
              <AirdropLeftTitle>Airdrop claim</AirdropLeftTitle>
            </AirdropLeftBlockTitle>
            <AirdropLeftBlockClaim>
              <AirdropLeftBlockClaimTitle>
                <h3>Your eligibility</h3>
                <AirdropLeftBlockClaimTotal>
                  5,443
                  <img src="/assets/images/Airdrop/Logo.png" alt="" />
                </AirdropLeftBlockClaimTotal>
              </AirdropLeftBlockClaimTitle>
              <AirdropLeftBlockBannerClaim>
                <AirdropLeftBlockBannerInner>
                  <span>Total airdrop</span>
                  <p>5,291,086.007</p>
                </AirdropLeftBlockBannerInner>
                <AirdropLeftBlockBannerImage>
                  <img src="/assets/images/Airdrop/airdrop_img_01.png" alt="" />
                </AirdropLeftBlockBannerImage>
              </AirdropLeftBlockBannerClaim>
              <AirdropLeftBlockProgress>
                <h3>Airdrop Progress</h3>
                <AirdropLeftBlockProgressDetail>
                  <span className="total">3,432,532 / 4,000,000,000</span>
                  <AirdropLeftBlockProgressDetailCompleted
                    style={{
                      transform: `translateX(-${completed}%) skew(-15deg)`,
                    }}
                  ></AirdropLeftBlockProgressDetailCompleted>
                </AirdropLeftBlockProgressDetail>
              </AirdropLeftBlockProgress>
              {address ? (
                <AirdropLeftConnectWallet onClick={handleClaimToken}>
                  Claim
                </AirdropLeftConnectWallet>
              ) : (
                <AirdropLeftConnectWallet
                  onClick={() => {
                    open();
                  }}
                >
                  Connect Wallet
                </AirdropLeftConnectWallet>
              )}
            </AirdropLeftBlockClaim>
          </AirdropLeft>
          <AirdropRight>
            <AirdropLeftBlockTitle>
              <AirdropLeftButtonsDecord>
                <li></li>
                <li></li>
                <li></li>
              </AirdropLeftButtonsDecord>
              <AirdropLeftTitle>HISTORY</AirdropLeftTitle>
            </AirdropLeftBlockTitle>
            <AirdropBlockRight>
              <AirdropBlockRightTable>
                <Table columns={columns} dataSource={data} pagination={false} />
              </AirdropBlockRightTable>
              <AirdropBlockRightFooter>
                <AirdropBlockRightPage>
                  <span>1/5</span>
                </AirdropBlockRightPage>
                <AirdropBlockRightListButtons>
                  <li>
                    <button>prev</button>
                  </li>
                  <li>
                    <button>Next</button>
                  </li>
                </AirdropBlockRightListButtons>
              </AirdropBlockRightFooter>
            </AirdropBlockRight>
          </AirdropRight>
        </AirdropWrapperInner>
      </AirdropWrapper>
      <GrowWave background="#fff" />
      <ModalClaim modalOpen={modalOpen} handleCloseModal={handleCloseModal} />
    </AirdropContainer>
  );
};

export default Airdrop;
