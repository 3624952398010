import {
  KeyvisualBackground,
  KeyvisualCloud,
  KeyvisualContainer,
  KeyvisualGrass,
  KeyvisualInfomation,
  KeyvisualSocial,
  KeyvisualWrapper,
} from "./styled";
import keyvisual_mountain from "../../../assets/Home/Keyvisual/keyvisual_mountain.png";
import GooseKick from "../../../components/Common/GooseKick";
import CommunityAction from "../../../components/Footer/Action";
import { useContext, useEffect, useState } from "react";
import { ContextProviderWrapper } from "../../../components/Context";
import annal1 from '../../../assets/Home/Keyvisual/anal1.png';
import annal2 from '../../../assets/Home/Keyvisual/anal2.png';
import annal3 from '../../../assets/Home/Keyvisual/anal3.png';
import annal4 from '../../../assets/Home/Keyvisual/anal4.png';
import { FooterNav } from "../../../components/Footer/styled";
import { Link } from "react-router-dom";

const Keyvisual = () => {
  const { isDesktop, isMobile } = useContext(ContextProviderWrapper)!;
  const [closeNotification, setCloseNotification] = useState(false);
  const [translateNotification, setTranslateNotification] = useState(false);
  useEffect(() => {
    if (!translateNotification) {
      setTimeout(() => {
        setTranslateNotification(true);
      }, 1000);
    }
  }, []);
  return (
    <KeyvisualContainer>
      <KeyvisualWrapper>
        <KeyvisualGrass>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </KeyvisualGrass>
        {!isMobile && (
          <KeyvisualCloud>
            <span></span>
            <span></span>
          </KeyvisualCloud>
        )}
        <KeyvisualInfomation>
          <KeyvisualSocial>
          <FooterNav>
            {navData.map((item, index) => {
              return (
                <div key={index}>
                  <ul>
                    {item.navLinks.map((links, i) => {
                      return (
                        <li key={i}>
                          {!links.external ? (
                            <Link to={links.href}>{links.title}</Link>
                          ) : (
                            <CommunityAction />
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            })}
          </FooterNav>
          </KeyvisualSocial>
          {/* <figure>
            <img src={keyvisual_logo} alt="logo" />
          </figure> */}
          {/* <InfomationMarquee>
            <Marquee direction="left">
              {marqueeData.map((item: any, index: number) => (
                <KeyvisualMarqueeItem to={"#"} key={index}>
                  {item.text} <span>{item.highlight}</span>
                </KeyvisualMarqueeItem>
              ))}
            </Marquee>
          </InfomationMarquee>
          <InfomationProgress>
            <IPTitle>PRe-sale process</IPTitle>
            <IPNumber>
              <p>0%</p>
              <p>100%</p>
            </IPNumber>
            <IPProgress>
              <span></span>
            </IPProgress>
          </InfomationProgress> */}
        </KeyvisualInfomation>
        <KeyvisualBackground>
          <GooseKick />
          <div className="mountain">
            <img className={translateNotification ? "annal1" : "annal1 annal1-init"} src={annal1} alt="icon" />
            <img className={translateNotification ? "annal2" : "annal2 annal2-init"} src={annal2} alt="icon" />
            <img className={translateNotification ? "annal3" : "annal3 annal3-init"} src={annal3} alt="icon" />
            <img className={translateNotification ? "annal4" : "annal4 annal4-init"} src={annal4} alt="icon" />
            <img className={translateNotification ? "annal5" : "annal5 annal5-init"} src="/assets/dog_icon.png" alt="icon" />
            <img  className="mountain-main" src={keyvisual_mountain} alt="mountain" />
          </div>
        </KeyvisualBackground>
      </KeyvisualWrapper>
      {/* {!isDesktop && (
        <KeyvisualNotification
          className={
            closeNotification && translateNotification
              ? "hide"
              : translateNotification && !closeNotification && isDesktop
              ? "hide appear"
              : !isDesktop
              ? "appear"
              : ""
          }
        >
          <NotificationClose
            onClick={() => {
              setCloseNotification(true);
            }}
          >
            X
          </NotificationClose>
          <NotificationContent>
            <p>Join poong community</p>
            <p>
              click on eyes icon to join poong
              <br /> community
            </p>
          </NotificationContent>
          <CommunityAction />
        </KeyvisualNotification>
      )} */}
    </KeyvisualContainer>
  );
};

const navData = [
  {
    navLinks: [
      {
        id: 1,
        title: "",
        href: "#",
        external: true,
      },
      {
        id: 2,
        title: "",
        href: "#",
        external: false,
      },
    ],
  },
];

const marqueeData = [
  {
    text: "Pre-Sale in dao maker twitter:",
    highlight: "CLICK TO SEE",
  },
  {
    text: "Pre-Sale in dao maker twitter:",
    highlight: "CLICK TO SEE",
  },
  {
    text: "Pre-Sale in dao maker twitter:",
    highlight: "CLICK TO SEE",
  },
  {
    text: "Pre-Sale in dao maker twitter:",
    highlight: "CLICK TO SEE",
  },
  {
    text: "Pre-Sale in dao maker twitter:",
    highlight: "CLICK TO SEE",
  },
  {
    text: "Pre-Sale in dao maker twitter:",
    highlight: "CLICK TO SEE",
  },
];

export default Keyvisual;
