import styled from "styled-components";
import decor_1 from "../../../assets/Home/Keyvisual/decor_1.png";
import cloud_1 from "../../../assets/Home/Keyvisual/cloud_1.png";
import cloud_2 from "../../../assets/Home/Keyvisual/cloud_2.png";
import grass_1 from "../../../assets/Home/Keyvisual/grass_1.png";
import grass_2 from "../../../assets/Home/Keyvisual/grass_2.png";
import { Link } from "react-router-dom";
import { GooseKickContainer } from "../../../components/Common/GooseKick/styled";
import { FooterActions, FooterNav } from "../../../components/Footer/styled";

export const KeyvisualContainer = styled.div`
  position: sticky;
  top: 0;
  background-color: #cce7fe;
  min-height: 100vh;
  overflow: hidden;
  &::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    pointer-events: none;
    background: url(${decor_1}) no-repeat center / cover;
    width: 3318px;
    height: 1896px;
    margin-left: -1659px;
    margin-top: -948px;
    mix-blend-mode: luminosity;
    animation: rotateDecor 20s linear infinite;
  }
  @keyframes rotateDecor {
    0% {
      transform: scale(1.2) rotate(0);
    }
    100% {
      transform: scale(2) rotate(360deg);
    }
  }
  @media screen and (max-width: 540px) {
    width: 100%;
    aspect-ratio: 2/3;
  }
  @media screen and (min-width: 541px) and (max-width: 767px) {
    width: 100%;
    aspect-ratio: 768/832;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    height: 100vh;
  }
  @media screen and (max-width: 1023px) {
    position: relative;
    overflow: hidden;
    min-height: unset;
  }
`;
export const KeyvisualWrapper = styled.div`
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 100vh;
  ${GooseKickContainer} {
    max-width: 445px !important;
  }
  @media screen and (max-width: 540px) {
    width: 100%;
    aspect-ratio: 2/3;
    height: unset;
  }
  @media screen and (min-width: 541px) and (max-width: 767px) {
    width: 100%;
    aspect-ratio: 768/832;
    height: unset;
  }
`;
export const KeyvisualBackground = styled.div`
  position: absolute;
  bottom: 83px;
  z-index: 5;
  pointer-events: none;
  width: 100%;

  .mountain {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 1;
    aspect-ratio: 2880 / 544;
    .mountain-main {
      position: absolute;
      width: 100%;
      z-index: 1;
    }
    .annal1 {
      position: absolute;
      width: 14.6%;
      z-index: -1;
      left: 5%;
      transform: translateY(-65%);
      transition: all 0.5s linear;
      animation-delay: 1s;
    }
    .annal1-init {
      transform: translateY(17%);
    }
    .annal2 {
      position: absolute;
      width: 13.5%;
      z-index: 2;
      left: 20%;
      transform: translateY(-20%);
      transition: all 0.5s linear;
    }
    .annal2-init {
      left: -15%;
    }
    .annal3 {
      position: absolute;
      width: 13.5%;
      z-index: 2;
      right: 20%;
      transform: translateY(-20%);
      transition: all 0.5s linear;
    }
    .annal3-init {
      right: -15%;
    }
    .annal4 {
      position: absolute;
      width: 9.3%;
      z-index: -1;
      right: 50px;
      transform: translateY(-90%);
      transition: all 0.5 linear;
    }
    .annal4-init {
      transform: translateY(17%);
    }

    .annal5 {
      position: absolute;
      width: 11.2%;
      z-index: 10;
      left: 0;
      transform: translateY(20px);
      transition: all 0.5 linear;
    }
    .annal5-init {
      transform: translateY(17%);
    }
  }
  ${GooseKickContainer} {
    /* left: -160px; */
    max-width: 60%;
    max-height: 54vh;
    // max-width: 436px;
    aspect-ratio: 436/492;
    left: 50%;
    transform: translateX(-50%);
    img {
      max-width: 100%;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    bottom: 0px;
    ${GooseKickContainer} {
      /* left: 0;
      bottom: 270px; */
    }
  }
  @media screen and (max-width: 767px) {
    bottom: 0px;
    .mountain {
      /* left: -520px;
      bottom: 40px;
      margin-left: 0;
      width: 1340px; */
    }
    ${GooseKickContainer} {
      /* bottom: 130px;
      left: -30px; */
    }
  }
`;
export const KeyvisualCloud = styled.div`
  position: absolute;
  width: 100%;
  top: 20%;
  left: 0;
  z-index: 5;
  span {
    position: absolute;
    top: 0;
    display: block;
    background: no-repeat center / 100% auto;
    width: 334px;
    aspect-ratio: 672/360;
    pointer-events: none;
    &:nth-child(1) {
      left: 50px;
      background-image: url(${cloud_1});
      animation: blowingCloud 15s linear infinite;
    }
    &:nth-child(2) {
      right: 50px;
      background-image: url(${cloud_2});
      animation: blowingCloudRevert 15s linear infinite;
    }
  }
  @keyframes blowingCloud {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(50%);
    }
    100% {
      transform: translateX(0);
    }
  }
  @keyframes blowingCloudRevert {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(-50%);
    }
    100% {
      transform: translateX(0);
    }
  }
  @media screen and (max-width: 767px) {
    top: 40%;
  }
`;
export const KeyvisualGrass = styled.div`
  position: absolute;
  inset: 0;
  pointer-events: none;
  overflow: hidden;
  z-index: 10;
  span {
    position: absolute;
    display: block;
    background: no-repeat center / 100% auto;
    transform: translateY(-50%);
    pointer-events: none;
    &:nth-child(even) {
      width: 82px;
      height: 72px;
      animation: animateGrass 4s linear infinite;
    }
    &:nth-child(odd) {
      width: 88px;
      height: 94px;
      animation: animateGrassRevert 4s linear infinite;
    }
    &:nth-child(1) {
      top: 0;
      left: 0;
      background-image: url(${grass_1});
      animation-delay: 0.2s;
    }
    &:nth-child(2) {
      top: 20%;
      right: 0;
      background-image: url(${grass_2});
      animation-delay: 0.4s;
    }
    &:nth-child(3) {
      top: 30%;
      left: 0;
      background-image: url(${grass_1});
      animation-delay: 0.2s;
    }
    &:nth-child(4) {
      top: 50%;
      right: 0;
      background-image: url(${grass_2});
      animation-delay: 0.4s;
    }
  }
  @keyframes animateGrass {
    0% {
      transform: translateY(0);
      opacity: 0;
    }
    70% {
      opacity: 1;
    }
    100% {
      transform: translate(-1000px, 1000px);
      opacity: 0;
    }
  }
  @keyframes animateGrassRevert {
    0% {
      transform: translateY(0);
      opacity: 0;
    }
    70% {
      opacity: 1;
    }
    100% {
      transform: translate(1000px, 1000px);
      opacity: 0;
    }
  }
`;
export const KeyvisualMarqueeItem = styled(Link)`
  margin: 0 20px;
  color: #1c619c;
  span {
    color: #ff7a00;
    text-align: center;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #fff;
    font-size: 15px;
  }
`;
export const KeyvisualInfomation = styled.div`
  position: absolute;
  width: calc(100vw - 80px);
  height: 100vh;
  left: 50%;
  top: 114px;
  border-radius: 51px;
  background: #c6e1f8;
  border: 20px solid #b6d5ef;
  padding: 15px 25px;
  pointer-events: none;
  transform: translateX(-50%);
  figure {
    position: relative;
    top: -35px;
    width: max-content;
    margin: 0 auto;
    z-index: 5;
    width: 70%;
    max-width: 248px;
    img {
      max-width: 100%;
    }
  }
  @media screen and (max-width: 767px) {
    width: 90%;
    top: 114px;
    height: 750px;
  }
`;
export const KeyvisualSocial = styled.div`
  width: 333.564px;
  height: 129.851px;
  margin: 0 auto;
  border-radius: 0 0 300px 300px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #b6d5ef;
  padding-top: 12px;
  pointer-events: auto;
  ${FooterNav} {
    justify-content: center;
  }
  ul {
    li {
      ${FooterActions} {
        width: 155px;
        .icon-social {
          width: 31px;
          height: 37px;
          img {
            width: 22px;
            height: 18px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 767.98px) {
    width: 250px;
    ul {
      li {
        ${FooterActions} {
          width: 122px;
        }
      }
    }
  }
`;
export const InfomationMarquee = styled.div`
  padding: 10px 0;
  border-radius: 18px;
  background: #b6d5ef;
  margin-bottom: 20px;
`;
export const InfomationProgress = styled.div`
  max-width: 570px;
  margin: 0 auto;
  padding: 15px;
  border-radius: 15px;
  border: 1px solid #fff;
  background: rgba(255, 255, 255, 0.33);
  box-shadow: 2px 3px 0px 0px rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(9px);
`;
export const IPTitle = styled.p`
  margin-bottom: 20px;
  color: #ff7a00;
  text-shadow: 0px 1.8px 0px #000;
  -webkit-text-stroke-width: 0.45px;
  -webkit-text-stroke-color: #fff;
  font-size: 18px;
`;
export const IPNumber = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  p {
    color: #6b7280;
    font-size: 14px;
  }
`;
export const IPProgress = styled.div`
  position: relative;
  width: 100%;
  border-radius: 2px;
  background: #c7c7c7;
  overflow: hidden;
  height: 6px;
  span {
    position: absolute;
    left: 0;
    width: 20%;
    top: 0;
    height: 100%;
    background-color: #0e9f6e;
    border-radius: 2px;
  }
`;
export const KeyvisualNotification = styled.div`
  position: fixed;
  right: 40px;
  bottom: 40px;
  z-index: 10;
  border-radius: 17px;
  background: #fff;
  box-shadow: 2px 3px 0px 0px rgba(0, 0, 0, 0.7);
  padding: 20px;
  display: flex;
  align-items: flex-start;
  gap: 40px;
  /* min-height: 80px; */
  transform: translateX(1000%);
  width: 100%;
  max-width: 415px;
  aspect-ratio: 414/130;
  &.hide {
    transform: scale(0) translateX(0);
    transition: all 0.5s linear;
  }
  &.appear {
    transform: scale(1) translateX(0);
    transition: all 1s linear;
  }
  /* @media screen and (max-width: 1023px) {
    position: absolute;
    right: 50%;
    width: max-content;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    margin-right: -220px;
    bottom: 53%;
  }
  @media screen and (max-width: 767px) {
    margin-right: -140px;
    display: block;
    bottom: 44%;
    ${FooterActions} {
      width: max-content;
      margin: 10px auto 0;
    }
  } */
  @media screen and (max-width: 767px) {
    right: 10px;
    bottom: 10px;
    max-width: 415px;
  }
  @media screen and (max-width: 435px) {
    width: 100vw;
    gap: 20px;
    max-width: calc(100vw - 20px);
    justify-content: space-between;
  }
`;
export const NotificationContent = styled.div`
  p {
    &:nth-child(1) {
      color: #ff7a00;
      text-shadow: 0px 2px 0px #000;
      -webkit-text-stroke-width: 0.46px;
      -webkit-text-stroke-color: #fff;
      font-size: 20px;
      font-weight: 400;
      line-height: 130%;
    }
    &:nth-child(2) {
      color: #323232;
      font-size: 14px;
      line-height: 130%;
      font-weight: 400;
    }
  }
  @media screen and (max-width: 435px) {
    p {
      &:nth-child(1) {
        font-size: 16px;
      }
      &:nth-child(2) {
        font-size: 12px;
      }
    }
  }
`;
export const NotificationClose = styled.p`
  position: absolute;
  right: 10px;
  top: -15px;
  color: #000;
  text-shadow: 0px 2px 0px #000;
  -webkit-text-stroke-width: 0.4px;
  -webkit-text-stroke-color: #fff;
  font-size: 25px;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: 0.212px;
  cursor: pointer;
`;
