import React from "react";
import "./modal.css";
import { Modal } from "antd";
import {
  ModalClaimBlockItem,
  ModalClaimButton,
  ModalClaimContainer,
  ModalClaimed,
  ModalClaimImage,
  ModalClaimItem,
  ModalClaimTotal,
} from "./styled";

const ModalClaim = ({ modalOpen, handleCloseModal }: any) => {
  const hash = '0xC3b5C89008A4F8D37B769dc67272485D6086b80C';
  return (
    <ModalClaimContainer>
      <Modal
        title="Congratulation"
        centered
        open={modalOpen}
        onCancel={handleCloseModal}
        footer={false}
        className="modal-claim"
      >
        <ModalClaimImage>
          <img src="/assets/images/Airdrop/img_modal_01.png" alt="" />
        </ModalClaimImage>
        <ModalClaimed>you’ve claimed</ModalClaimed>
        <ModalClaimTotal>5,291,086.007</ModalClaimTotal>
        <ModalClaimBlockItem>
          <ModalClaimItem>
            <p className="text-left">transaction hash</p>
            <p className="text-right">{`${hash.slice(0,12)}...`}</p>
          </ModalClaimItem>
        </ModalClaimBlockItem>
        <ModalClaimButton onClick={handleCloseModal}>got it</ModalClaimButton>
      </Modal>
    </ModalClaimContainer>
  );
};

export default ModalClaim;
