import styled from "styled-components";
import flame from "../../assets/gif/flame.gif";
import { GrowWaveWrapper } from "../Home/Grow/styled";

export const AirdropContainer = styled.section`
  background-color: rgb(204, 231, 254);
  padding-top: 116px;
  position: relative;
  ${GrowWaveWrapper} {
    top: unset;
    bottom: 0;
  }
`;
export const AirdropWrapper = styled.div`
  max-width: 1395px;
  margin: 0 auto;
  padding: 0 15px;
`;
export const AirdropWrapperInner = styled.div`
  background: #ffc715;
  border-radius: 27px;
  border: solid 16px #000;
  min-height: 500px;
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 54px 25px 150px;
  gap: 20px;
  @media screen and (max-width: 1000px) {
    display: block;
    border-width: 6px;
    padding-left: 10px;
    padding-right: 15px;
  }
`;
export const AirdropDecor = styled.div`
  position: absolute;
  top: -54px;
  right: -60px;
  z-index: 1;
  pointer-events: none;
  width: 179px;
  animation: animateGrassRevert 6s linear infinite;
  transform: translateY(-50%);
  img {
    width: 100%;
  }
  @keyframes animateGrassRevert {
    0% {
      transform: translateY(0);
      opacity: 0;
    }
    70% {
      opacity: 1;
    }
    100% {
      transform: translate(-1000px, 1000px);
      opacity: 0;
    }
  }
`
export const AirdropEar = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  border-radius: var(--Click, 0px) var(--Click, 0px) 12px 12px;
  background: #000;
  width: 134px;
  height: 30px;
`;
export const AirdropLeft = styled.div`
  width: 50%;
  box-shadow: 8px 8px 0px 0px #000;
  border-radius: 27px;
  overflow: hidden;
  @media screen and (max-width: 1000px) {
    width: 100%;
  }
`;
export const AirdropLeftBlockTitle = styled.div`
  position: relative;
  background-color: #000;
  padding: 9px 12px 9px 12px;
  border-radius: 23px 23px 0 0;
  border-bottom: solid 2px #e9e9e9;
`;
export const AirdropLeftButtonsDecord = styled.ul`
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  gap: 7px;
  li {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    &:nth-child(1) {
      background-color: #ff5a52;
    }
    &:nth-child(2) {
      background-color: #e6c029;
    }
    &:nth-child(3) {
      background-color: #53c22b;
    }
  }
`;
export const AirdropLeftTitle = styled.h1`
  text-align: center;
  color: #ffc715;
  text-align: center;
  font-size: 20px;
`;
export const AirdropLeftBlockClaim = styled.div`
  background: #323232;
  padding: 24px;
`;
export const AirdropLeftBlockClaimTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  h3 {
    color: #fff;
    text-align: center;
    font-size: 20px;
  }
  @media screen and (max-width: 767.98px) {
    h3 {
      font-size: 18px;
    }
  }
`;
export const AirdropLeftBlockClaimTotal = styled.div`
  border-radius: 10px;
  background: #fff;
  box-shadow: 2px 4px 0px 0px #000;
  padding: 9px 44.036px 9.036px 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #e72d36;
  text-align: center;
  font-size: 24px;
  img {
    width: 30px;
    height: 30px;
    object-fit: cover;
  }
  @media screen and (max-width: 767.98px) {
    padding: 9px 15px;
    font-size: 20px;
  }
`;
export const AirdropLeftBlockBannerClaim = styled.div`
  background: linear-gradient(180deg, #ff4343 0%, #ffd3aa 59.93%, #fffefc 100%);
  min-height: 226px;
  border-radius: 15px;
  box-shadow: 2px 4px 0px 0px #000;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 12px;
  @media screen and (max-width: 767.98px) {
    display: block;
    padding-top: 10px;
  }
`;
export const AirdropLeftBlockBannerImage = styled.div`
  position: absolute;
  right: 0;
  bottom: -4px;
  width: 257px;
  &:before {
    content: "";
    position: absolute;
    background: no-repeat center / 100% auto;
    background-image: url(${flame});
    width: 77%;
    /* height: 540px; */
    aspect-ratio: 437 / 498;
    left: 0px;
    top: -35px;
    transform: translateX(-24.5%) translateY(-37%) scale(1.3);
    mix-blend-mode: color-dodge;
    pointer-events: none;
  }
  img {
    width: 100%;
  }
  @media (min-width: 767.98px) and (max-width: 1330px) {
    width: 180px;
  }

  @media screen and (max-width: 767.98px) {
    width: 180px;
  }
`;
export const AirdropLeftBlockBannerInner = styled.div`
  text-align: center;
  padding-right: 160px;
  padding-bottom: 30px;
  span {
    color: #fff;
    text-align: center;
    font-size: 20px;
  }
  p {
    color: #000;
    font-size: 40px;
  }
  @media (min-width: 767.98px) and (max-width: 1330px) {
    p {
      font-size: 30px;
    }
  }
  @media screen and (max-width: 767.98px) {
    padding-right: 0;
    padding-bottom: 0;
  }
`;
export const AirdropLeftBlockProgress = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-bottom: 60px;
  h3 {
    color: #fff;
    text-align: center;
    font-size: 20px;
  }
  @media screen and (max-width: 767.98px) {
    display: block;
    text-align: center;
  }
`;
export const AirdropLeftBlockProgressDetail = styled.div`
  border-radius: 5px;
  background: #ffc715;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.25) inset;
  width: 50%;
  text-align: center;
  min-height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  .total {
    position: relative;
    z-index: 1;
    text-align: center;
    font-size: 12px;
  }
  @media screen and (max-width: 767.98px) {
    margin-top: 6px;
    width: 100%
  }
`;
export const AirdropLeftBlockProgressDetailCompleted = styled.span`
  position: absolute;
  top: 0;
  left: -10px;
  background-color: #e72d36;
  box-shadow: -2px 0px 0px 0px rgba(0, 0, 0, 0.25) inset;
  filter: drop-shadow(2px 0px 0px #e19200);
  width: calc(100% + 15px);
  height: 100%;
`;
export const AirdropLeftConnectWallet = styled.button`
  font-family: iCiel;
  text-align: center;
  font-size: 18px;
  border-radius: 10px;
  background: #ffc715;
  box-shadow: 2px 4px 0px 0px #000;
  padding: 12px 28px;
  width: 100%;
  outline: none;
  border: none;
  cursor: pointer;
`;
export const AirdropRight = styled.div`
  width: 50%;
  box-shadow: 8px 8px 0px 0px #000;
  border-radius: 27px;
  overflow: hidden;
  background: #f5f5f5;
  ${AirdropLeftBlockTitle} {
    background: #fff;
  }
  ${AirdropLeftTitle} {
    color: #E72D36;
  }
  @media screen and (max-width: 1000px) {
    margin-top: 20px;
    width: 100%;
  }
`;
export const AirdropBlockRight = styled.div`
  background: #f5f5f5;
  padding: 3px 20px;
  display: flex;
  flex-direction: column;
  height: 88.2%;
  overflow: auto;
  @media screen and (max-width: 1000px) {
    padding-bottom: 20px;
  }
`;
export const AirdropBlockAddress = styled.div`
  border-radius: 4.603px;
  border: 0.46px solid #bcbcbc;
  background: #000;
  padding: 2px 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  span {
    color: #ffc715;
    font-size: 13px;
    font-weight: 500;
  }
  img {
    width: 13px;
    cursor: pointer;
  }
`;
export const AirdropBlockTime = styled.time`
  color: #e72d36;
  text-align: center;
  font-size: 13px;
  display: block;
`;
export const AirdropBlockHash = styled.p`
  color: #627b38;
  text-align: center;
  font-size: 13px;
`;
export const AirdropBlockAmount = styled.p`
  text-align: center;
  font-size: 13px;
`;
export const AirdropBlockRightTable = styled.div`
  .ant-table-wrapper table {
    border-collapse: separate;
    border-spacing: 0 5px;
  }
  .ant-table {
    background: unset;
    font-family: iCiel;
  }
  .ant-table-thead {
    border-radius: 11px;
  }
  .ant-table-wrapper .ant-table-thead > tr > th,
  .ant-table-wrapper .ant-table-thead > tr > td {
    border-bottom: none;
    text-align: center;
    font-family: iCiel;
    background: #e7e7e7;
  }
  .ant-table-wrapper
    .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before,
  .ant-table-wrapper
    .ant-table-thead
    > tr
    > td:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    content: none;
  }
  .ant-table-wrapper .ant-table-tbody > tr > th,
  .ant-table-wrapper .ant-table-tbody > tr > td {
    border-bottom: none;
    background: #e7e7e7;
  }
  .ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:first-child {
    border-bottom-left-radius: 8px;
  }
  .ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:last-child {
    border-bottom-right-radius: 8px;
  }
  .ant-table-wrapper .ant-table-cell,
  .ant-table-wrapper .ant-table-thead > tr > th,
  .ant-table-wrapper .ant-table-tbody > tr > th,
  .ant-table-wrapper .ant-table-tbody > tr > td,
  .ant-table-wrapper tfoot > tr > th,
  .ant-table-wrapper tfoot > tr > td {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .ant-table-tbody {
    .ant-table-cell {
      &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
      &:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
  }
`;

export const AirdropBlockRightFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
  @media screen and (max-width: 1000px) {
    margin-top: 12px;
  }
`
export const AirdropBlockRightPage = styled.div`
  border-radius: 10px;
  border: var(--cart, 1px) solid #9A9A9A;
  padding: 11px 0px 13px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 92px;
  span {
    color: #323232;
    text-align: center;
    font-size: 15px;
  }
  @media screen and (max-width: 767.98px) {
    width: 50px;
    padding-top: 7px;
    padding-bottom: 7px;
  }
`
export const AirdropBlockRightListButtons = styled.ul`
  display: flex;
  align-items: center;
  gap: 8px;
  li {
    box-shadow: 2px 4px 0px 0px #000;
    border-radius: 10px;
    &:nth-child(1) {
      background: #323232;
    }
    &:nth-child(2) {
      background: #FFC715;
      button {
        color: #000;
      }
    }
    button {
      color: #FFC715;
      text-align: center;
      font-size: 15px;
      outline: none;
      border: none;
      background-color: unset;
      width: 120px;
      padding: 12px 28px;
      font-family: iCiel;
      cursor: pointer;
    }
  }
  @media screen and (max-width: 767.98px) {
    li {
      button {
        width: 80px;
      }
    }
  }
`