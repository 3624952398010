import styled from "styled-components";
import { TitleCommonContainer } from "../../../components/Common/Title/styled";
import about_bg from "../../../assets/Home/About/about_bg.png";
import honor from "../../../assets/Home/Nomics/honor.png";
import flame from "../../../assets/gif/flame.gif";
import bgButton from "../../../assets/Home/Nomics/bgButton.png";

export const NomicsContainer = styled.div`
  position: relative;
  background-color: #323232;
  padding: 120px 0px 52px;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 470px;
    background: url(${about_bg}) no-repeat center / cover;
    pointer-events: none;
  }
  @media screen and (max-width: 1023px) {
    overflow: hidden;
    height: auto;
    padding-top: 50px;
  }
`;
export const NomicsWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 25px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
  width: 100%;
  ${TitleCommonContainer} {
    margin-bottom: 45px;
  }
  & > figure {
    position: relative;
    margin-top: auto;
    margin: 0 auto;
    img {
      position: relative;
      z-index: 5;
    }
    &::after {
      content: "";
      position: absolute;
      background: no-repeat center / 100% auto;
      background-image: url(${flame});
      width: 77%;
      /* height: 540px; */
      aspect-ratio: 437 / 498;
      left: 0px;
      top: 0;
      transform: translateX(-24.5%) translateY(-37%) scale(1.3);
      mix-blend-mode: color-dodge;
    }
  }
  @media screen and (max-width: 1023px) {
    flex-direction: column-reverse;
    padding: 0 15px 20px;
    width: auto;
    gap: 50px;
    & > figure {
      width: 265px;
      margin: 0 auto;
      img {
        width: 100%;
      }
    }
  }
`;
export const NomicsList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 6px;
  margin-bottom: 20px;
  & > li {
    text-align: center;
    width: 100%;
    min-height: 60px;
    padding: 20px;
    border-radius: 15px;
    background: #ffc715;
    box-shadow: 2px 4px 0px 0px #000;
    transition: all .26s ease;
    &:hover {
      background-color: #fff;
    }
    p {
      display: block;
      color: #000;
      font-size: 20px;
      white-space: pre-line;
      line-height: 150%;
    }
    span {
      display: block;
      color: #7ec900;
      text-align: center;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: #000;
      font-size: 34px;
    }
  }
  @media screen and (max-width: 767.98px) {
    & > li {
      padding: 10px;
      p {
        font-size: 16px;
      }
      span {
        font-size: 32px;
      }
    }
  }
`;
export const NomicsInner = styled.div`
  width: 90%;
  max-width: 530px;
  margin-right: 50px;
  @media screen and (max-width: 1023px) {
    margin-right: 0;
    margin: 0 auto;
  }
`;
export const NomicsHint = styled.p`
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: 7px;
  color: #fff;
  text-shadow: 0px 1px 0px #efb000;
  -webkit-text-stroke-width: 0.5px;
  -webkit-text-stroke-color: #aa1800;
  font-size: 16px;
  line-height: 130%;
  z-index: 1;
  p {
    &:nth-child(1) {
      font-size: 30px;
    }
  }
  &::before {
    content: "";
    position: absolute;
    background: no-repeat center / 100% auto;
    bottom: -20px;
    left: -0%;
    width: 110px;
    height: 110px;
    background-image: url(${honor});
    transform: translateX(-110%);
    z-index: -1;
  }
  @media screen and (max-width: 1023px) {
    width: 70%;
    &::before {
      right: -105px;
      left: unset;
      transform: unset;
    }
    .sm {
      display: none;
    }
  }
`;
export const DesGroup = styled.div`
  padding: 10px 24px;
  border-radius: 15px;
  background: #fff;
  box-shadow: 2px 4px 0px 0px #000;
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 40px;
`;
export const Des = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h3 {
    color: #000;
    text-align: left;
    font-family: "iCiel";
    font-size: 20px;
    font-weight: 400;
    line-height: 150%;
  }
  p {
    color: #efb000;
    text-align: right;
    font-family: "iCiel";
    font-size: 20px;
    font-weight: 400;
    line-height: 150%;
    button {
      width: 22.805px;
      height: 29.194px;
      background-image: url(${bgButton});
      background-size: cover;
      border: none;
    }
  }
  @media only screen and (max-width: 540px) {
    h3 , p {
      font-size: 14px;
    }
  }
`;
