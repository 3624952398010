import styled from "styled-components";

export const ModalClaimContainer = styled.div`
  font-family: iCiel;
`
export const ModalClaimImage = styled.div`
  width: 100px;
  margin: 0 auto 20px;
  img {
    width: 100%;
  }
`
export const ModalClaimed = styled.h3`
  color: #E72D36;
  text-align: center;
  font-size: 20px;
  font-family: iCiel;
  margin-bottom: -10px;
`
export const ModalClaimTotal = styled.p`
  text-align: center;
  font-size: 40px;
  font-family: iCiel;
  margin-bottom: 20px;
`
export const ModalClaimBlockItem = styled.div``
export const ModalClaimItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: iCiel;
  background-color: #fff;
  padding: 8px 15px;
  border-radius: 8px;
  &:not(:last-child) {
    margin-bottom: 5px;
  }
  .text-left {
    text-align: center;
    font-size: 16px;
  }
  .text-right {
    color: #E72D36;
    text-align: center;
    font-size: 16px;
  }
`
export const ModalClaimButton= styled.button`
  margin-top: 20px;
  text-align: center;
  font-family: iCiel;
  font-size: 18px;
  width: 100%;
  border-radius: 10px;
  background: #FFC715;
  box-shadow: 2px 4px 0px 0px #000;
  padding: 12px 28px;
  border: none;
  outline: none;
  cursor: pointer;
`